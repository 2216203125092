import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import globalEn from './locales/en/global.json';
import globalRu from './locales/ru/global.json';
import mainEn from './locales/en/main.json';
import mainRu from './locales/ru/main.json';
import progressEn from './locales/en/progress-cards.json';
import progressRu from './locales/ru/progress-cards.json';
import aboutEn from './locales/en/about.json';
import aboutRu from './locales/ru/about.json';
import donateEn from './locales/en/donate.json';
import donateRu from './locales/ru/donate.json';
import tournamentEn from './locales/en/tournament.json';
import tournamentRu from './locales/ru/tournament.json';
import authEn from './locales/en/auth.json';
import authRu from './locales/ru/auth.json';
import videoEn from './locales/en/video.json';
import videoRu from './locales/ru/video.json';

import { Languages as TLanguages } from '@/shared/types';

const getCookieDomain = () => {
    if (window.location.hostname === 'localhost') {
        return undefined;
    }

    return '.' + window.location.hostname;
};

export const Languages: Record<TLanguages, string> = { en: 'English', ru: 'Русский' };

export const i18m = i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: process.env.NODE_ENV === 'development',
        detection: {
            order: ['queryString', 'cookie', 'localStorage', 'navigator'],
            caches: ['cookie'],
            cookieMinutes: 43200,
            cookieDomain: getCookieDomain(),
        },
        supportedLngs: ['en', 'ru'],
        nonExplicitSupportedLngs: true,
        cache: ['cookie'],
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: {
                global: globalEn,
                main: mainEn,
                progress: progressEn,
                about: aboutEn,
                donate: donateEn,
                tournament: tournamentEn,
                auth: authEn,
                video: videoEn,
            },
            ru: {
                global: globalRu,
                main: mainRu,
                progress: progressRu,
                about: aboutRu,
                donate: donateRu,
                tournament: tournamentRu,
                auth: authRu,
                video: videoRu,
            },
        },
        ns: ['global', 'main', 'progress', 'about', 'donate', 'tournament', 'auth', 'video'],
        defaultNS: 'global',
    });

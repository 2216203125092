import { useDidMount } from '@/shared/hooks/useDidMount';
import React, { FormEvent } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

const TinkoffWidget: React.FC = () => {
    const { t } = useTranslation('donate');
    useDidMount(() => {
        const tinkoffWidgenContainer = document.getElementById('tinfoffWidgenContainer');
        const tinkoffPayform = document.getElementById('payform-tinkoff');
        const script = document.createElement('script');
        if (tinkoffWidgenContainer) {
            script.src = 'https://securepay.tinkoff.ru/html/payForm/js/tinkoff_v2.js';
            tinkoffWidgenContainer.insertBefore(script, tinkoffPayform);
        }

        return () => {
            if (tinkoffWidgenContainer) tinkoffWidgenContainer.removeChild(script);
        };
    });

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const TPF = e.currentTarget;
        const description = (TPF.elements.namedItem('description') as HTMLInputElement).value;
        const amount = (TPF.elements.namedItem('amount') as HTMLInputElement).value;
        const email = (TPF.elements.namedItem('email') as HTMLInputElement).value;
        const phone = (TPF.elements.namedItem('phone') as HTMLInputElement).value;
        const receipt = TPF.elements.namedItem('receipt') as HTMLInputElement;

        if (receipt) {
            if (!email && !phone) {
                alert(t('ErrContacts'));
                return;
            }

            receipt.value = JSON.stringify({
                Taxation: 'usn_income_outcome',
                FfdVersion: '1.2',
                Items: [
                    {
                        Name: description || 'Пожертвование',
                        Price: amount + '00',
                        Quantity: 1.0,
                        Amount: amount + '00',
                        PaymentMethod: 'full_payment',
                        PaymentObject: 'another',
                        Tax: 'none',
                        MeasurementUnit: 'pc',
                    },
                ],
            });
        }

        if (window.TinkoffWidget && window.TinkoffWidget.pay) {
            window.TinkoffWidget.pay(TPF);
        } else {
            alert(t('ErrInit'));
        }
    };

    return (
        <form className={styles['payform-tinkoff']} name="payform-tinkoff" id="payform-tinkoff" onSubmit={handleSubmit}>
            <input className={styles['payform-tinkoff-row']} type="hidden" name="terminalkey" value="1722008422073" />
            <input className={styles['payform-tinkoff-row']} type="hidden" name="frame" value="false" />
            <input className={styles['payform-tinkoff-row']} type="hidden" name="language" value="ru" />
            <input
                className={styles['payform-tinkoff-row']}
                type="text"
                placeholder={t('Amount')}
                name="amount"
                required
            />
            <input
                className={styles['payform-tinkoff-row']}
                type="hidden"
                placeholder={t('Order number')}
                name="order"
            />
            <input
                className={styles['payform-tinkoff-row']}
                type="hidden"
                placeholder={t('Description')}
                name="description"
            />
            <input
                className={styles['payform-tinkoff-row']}
                type="text"
                placeholder={t('Full name')}
                name="name"
                required
            />
            <input className={styles['payform-tinkoff-row']} type="email" placeholder="E-mail" name="email" />
            <input className={styles['payform-tinkoff-row']} type="tel" placeholder={t('Phone')} name="phone" />
            <input
                className={`${styles['payform-tinkoff-row']} ${styles['payform-tinkoff-btn']}`}
                type="submit"
                value={t('Pay')}
            />
        </form>
    );
};

export default TinkoffWidget;

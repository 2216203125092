import React from 'react';
import styles from './styles.module.scss';
import { randomId } from '../../utils';
import classNames from 'classnames';

type InputProps = {
    label?: string;
    error?: string;
};

export const Input = React.forwardRef<HTMLInputElement, React.ComponentPropsWithoutRef<'input'> & InputProps>(
    (props, ref) => {
        const { label, className, error, ...inputProps } = props;

        if (!inputProps.id) inputProps.id = randomId();

        return (
            <div className={classNames(styles.container, className)}>
                <div className={classNames(styles['input-box'], { [styles.red]: !!error })}>
                    <input className={styles.input} {...inputProps} placeholder={label} ref={ref}></input>
                    <label className={styles.label} htmlFor={inputProps.id}>
                        {label}
                    </label>
                </div>
                {error && <span className={styles.error}>{error}</span>}
            </div>
        );
    }
);

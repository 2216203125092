import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const scrollPositions: Record<string, number> = {};

const ScrollRestoration: React.FC = () => {
    const location = useLocation();
    const prevPath = useRef<string | null>(null);

    useEffect(() => {
        if (prevPath.current) {
            scrollPositions[prevPath.current] = window.scrollY;
        }

        const savedScrollPosition = scrollPositions[location.pathname];
        if (savedScrollPosition !== undefined) {
            window.scrollTo(0, savedScrollPosition);
        } else {
            window.scrollTo(0, 0);
        }

        prevPath.current = location.pathname;
    }, [location.pathname]);

    return null;
};

export default ScrollRestoration;

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LogRequest } from '../models/LogRequest';
import type { Logs } from '../models/Logs';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class LogsService {
  /**
   * Post a log
   * @returns any OK
   * @throws ApiError
   */
  public static postLog({
    requestBody,
  }: {
    /**
     * Filename to submit for recognition.
     */
    requestBody: LogRequest,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/log',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Get logs
   * @returns Logs OK
   * @throws ApiError
   */
  public static getLog({
    since,
  }: {
    since?: string,
  }): CancelablePromise<Logs> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/log',
      query: {
        'since': since,
      },
    });
  }
}

import { usePlayers } from '@/entities/players/model/usePlayers';
import { useProfile } from '@/entities/profile/model/useProfile';
import { useTournamentList } from '@/entities/tournaments/model/useTournamentList';
import React, { ReactNode, createContext, useContext } from 'react';

type Store = {
    players: ReturnType<typeof usePlayers> | null;
    tournaments: ReturnType<typeof useTournamentList> | null;
    player: ReturnType<typeof useProfile> | null;
};

const Context = createContext<Store>({
    players: null,
    tournaments: null,
    player: null,
});

export const StoreProvider = ({ children }: { children: ReactNode }) => {
    const players = usePlayers();
    const tournaments = useTournamentList();
    const player = useProfile();
    return <Context.Provider value={{ players, tournaments, player }}>{children}</Context.Provider>;
};

export const usePlayersContext = () => {
    const { players } = useContext(Context);
    if (players === undefined) {
        throw new Error('usePlayersContext must be used within a StoreProvider');
    }
    return players;
};

export const useTournamentsContext = () => {
    const { tournaments } = useContext(Context);
    if (tournaments === undefined) {
        throw new Error('useTournamentsContext must be used within a StoreProvider');
    }
    return tournaments;
};

export const usePlayerContext = () => {
    const { player } = useContext(Context);
    if (player === undefined) {
        throw new Error('usePlayerContext must be used within a StoreProvider');
    }
    return player;
};

import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

type LanguageButtonProps = {
    language: string;
    current: string;
    change: (lang: string) => void;
};

export const LanguageButton: React.FC<LanguageButtonProps> = ({ language, current, change }) => (
    <a
        className={classNames(styles['dropdown-item'], { [styles['is-active']]: current === language })}
        onClick={() => change(language)}
    >
        {language.toUpperCase()}
    </a>
);

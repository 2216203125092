import React, { useState } from 'react';
import styles from './styles.module.scss';
import Modal from 'react-modal';
import { t } from 'i18next';
import close from '@app/ui/header/assets/close.svg';
import { useForm } from 'react-hook-form';
import { Input } from '@/shared/ui/input/Input';
import { FileUploadWithDragAndDrop } from '@/shared/ui/fileUploadWithDragAndDrop/fileUploadWithDragAndDrop';
import { FeedbackRequest, UserService } from '@/shared/api';
import { useAuth } from '@/app/AuthHook';
import Error from '@pages/Auth/assets/Error.svg';
import { FileWithURL, useChangeSubmitFeedback } from '../model/useChangeSubmitFeedback';
import Loader from '@/shared/ui/loader/loader';
import classNames from 'classnames';
import { InfoPage } from '@/shared/ui/InfoPage/InfoPage';
import SuccessImg from '@pages/Auth/assets/Success.svg';

type Props = {
    onClick?: () => void;
};

const Success: React.FC = () => (
    <div className={styles.Success}>
        <InfoPage title={t('Feedback sent')} img={SuccessImg} subtitle={t('FeedbackSuccessInfo')} />
    </div>
);

export const Feedback: React.FC<Props> = ({ onClick }) => {
    const { user } = useAuth();
    const [isOpen, setIsOpen] = useState(false);
    const [formats, setFormats] = useState<string[]>();
    const [maxSize, setMaxSize] = useState<number>();
    const [files, setFiles] = useState<Array<FileWithURL>>([]);
    const [errorUpload, setErrorUpload] = useState('');
    const [error, setError] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [isSucess, setIsSuccess] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<FeedbackRequest>();

    const openModal = () => {
        onClick && onClick();
        setIsOpen(true);
        UserService.getFeedbackFormats().then((res) => {
            setFormats(res.Formats);
            setMaxSize(res.MaxSize);
        });
    };
    const closeModal = () => {
        setIsOpen(false);
        setIsSuccess(false);
    };

    const { onSubmit } = useChangeSubmitFeedback({
        files,
        setFiles,
        setError,
        setErrorUpload,
        setUploading,
        setValue,
        setIsSuccess,
    });

    return (
        <>
            <button className={styles.Button} onClick={openModal}>
                {t('feedbackButton')}
            </button>
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                className={styles.modalContent}
                overlayClassName={styles.modalOverlay}
                bodyOpenClassName={styles.modalOpen}
            >
                <button onClick={closeModal} className={styles.closeButton}>
                    <img src={close} alt="close" />
                </button>
                {isSucess ? (
                    <Success />
                ) : (
                    <form className={styles.modalForm} onSubmit={handleSubmit(onSubmit)}>
                        <h1>{t('feedback')}</h1>
                        {error && (
                            <div className={styles.error}>
                                <img src={Error} alt="error" />
                                <span>{t('feedbackError')}</span>
                            </div>
                        )}
                        <Input
                            className={styles.input}
                            label={t('yourName')}
                            defaultValue={user?.Name || ''}
                            {...register('Name', { required: t('nameRequired') })}
                            error={errors.Name?.message}
                        />
                        <Input
                            className={styles.input}
                            label={t('email')}
                            defaultValue={user?.EMail || ''}
                            type="email"
                            {...register('Email', { required: t('emailRequired') })}
                            error={errors.Email?.message}
                        />
                        <FileUploadWithDragAndDrop
                            files={files}
                            maxSize={maxSize}
                            setFiles={setFiles}
                            formats={formats}
                            error={errorUpload}
                            setError={setErrorUpload}
                        />
                        <div>
                            <textarea
                                className={classNames(styles.feedback, {
                                    [styles.feedback__error]: errors.Message?.message,
                                })}
                                placeholder={t('feedbackMessage')}
                                {...register('Message', {
                                    required: t('messageRequired'),
                                    maxLength: { value: 1000, message: t('maxMessageLength') },
                                })}
                            />
                            {errors.Message?.message && (
                                <span className={styles.errorMessage}>{errors.Message.message}</span>
                            )}
                        </div>
                        <button type="submit" className={styles.submit} disabled={uploading}>
                            {uploading ? <Loader /> : t('submit').toUpperCase()}
                        </button>
                    </form>
                )}
            </Modal>
        </>
    );
};

import { FeedbackRequest, UserService } from '@/shared/api';
import { t } from 'i18next';
import { UseFormSetValue } from 'react-hook-form';

export type FileWithURL = File & {
    urlFile?: string;
};

type Props = {
    files: FileWithURL[];
    setFiles: React.Dispatch<React.SetStateAction<FileWithURL[]>>;
    setErrorUpload: React.Dispatch<React.SetStateAction<string>>;
    setError: (error: boolean) => void;
    setUploading: (uploading: boolean) => void;
    setValue: UseFormSetValue<FeedbackRequest>;
    setIsSuccess: (isSuccess: boolean) => void;
};

export const useChangeSubmitFeedback = ({
    files,
    setFiles,
    setError,
    setErrorUpload,
    setUploading,
    setValue,
    setIsSuccess,
}: Props) => {
    const errorList: string[] = [];
    const errorList413: string[] = [];
    const onSubmit = (data: FeedbackRequest) => {
        setUploading(true);
        Promise.all(
            files.map((file) => {
                if (file.urlFile) return file;
                else {
                    return UserService.postFeedbackUpload({ formData: { fileName: file } })
                        .then((res) => {
                            file.urlFile = res.Name;
                            return file;
                        })
                        .catch((e) => {
                            e.status === 413 ? errorList413.push(file.name) : errorList.push(file.name);
                            return file;
                        });
                }
            })
        ).then((updatedFiles) => {
            setFiles(updatedFiles);
            setError(false);
            setErrorUpload('');
            if (errorList.length || errorList413.length) {
                errorList413.length && setErrorUpload(`${errorList413.join(' ')} ${t('feedbackErrorUploadTooLarge')}`);
                errorList.length &&
                    setErrorUpload((prev) => `${prev} ${errorList.join(' ')} ${t('feedbackErrorUpload')}`);
                setUploading(false);
                return;
            }
            data.Attachments = updatedFiles.map((file) => file.urlFile || '');
            UserService.postFeedback({ requestBody: data })
                .then(() => {
                    setFiles([]);
                    setValue('Message', '');
                    setIsSuccess(true);
                    setError(false);
                })
                .catch(() => {
                    setError(true);
                })
                .finally(() => {
                    setUploading(false);
                });
        });
    };

    return {
        onSubmit,
    };
};

import React, { createContext, useState } from 'react';

import { User } from '@shared/types';

type ContextProps = {
    user: User | null;
    setUser: (user: User | null) => void;
};

export const AuthContext = createContext<Partial<ContextProps>>({});

export const AuthProvider = ({ children }: any) => {
    const [user, setUser] = useState<User | null>(null);
    return (
        <AuthContext.Provider
            value={{
                user,
                setUser,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

import React, { useState } from 'react';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import logo from './assets/logo.svg';
import { Donate } from '@/features/changeDonate/ui/Donate';
import { t } from 'i18next';
import arrow from './assets/arrow.svg';
import { Tooltip } from 'react-tooltip';
import { copyToClipbord } from '@/shared/utils';

export const Footer: React.FC = () => {
    const [isOpenTooltip, setIsOpenTooltip] = useState(false);
    const handleClickEmail = () => {
        copyToClipbord('support@sputnik-go.com', setIsOpenTooltip);
    };
    return (
        <footer className={styles.footer}>
            <div className={styles.topLine}>
                <Link className={styles.logo} to="/">
                    <span className={styles.LogoTitle}>{t('title').toUpperCase()}</span>
                    <img src={logo} alt="Go Companion" />
                </Link>
                <div className={styles.pages}>
                    <Link to="/players" onClick={() => window.scrollTo(0, 0)}>
                        {t('menuPlayers')}
                    </Link>
                    <Link to="/tournaments" onClick={() => window.scrollTo(0, 0)}>
                        {t('menuTournaments')}
                    </Link>
                    <Link to="/about">{t('menuAboutCompany')}</Link>
                    <Donate className={styles.donate} />
                </div>
                <div className={styles.contacts}>
                    <span className={styles.email} onClick={handleClickEmail} data-tooltip-id="email">
                        support@sputnik-go.com
                    </span>
                    <Tooltip id="email" place="bottom" variant="light" opacity={1} isOpen={isOpenTooltip}>
                        Скопировано
                    </Tooltip>
                    <a href="https://t.me/GoCompanion" target="_blank">
                        {t('Telegram_ru')} <img src={arrow} alt="telegram" />
                    </a>
                    <a href="https://t.me/GoCompanionWorld" target="_blank">
                        {t('Telegram_en')} <img src={arrow} alt="telegram" />
                    </a>
                </div>
            </div>
            <div className={styles.bottomLine}>
                {/* <span>Политика конфиденциальности</span> */}
                <span>{t('footerSupport')}</span>
                <span>© 2024</span>
            </div>
        </footer>
    );
};

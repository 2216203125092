import React, { useState, DragEvent, useEffect } from 'react';
import styles from './styles.module.scss';
import Clip from './assets/Clip.svg';
import close from '@app/ui/header/assets/close.svg';
import classNames from 'classnames';
import { useIsMobile } from '@/shared/utils';
import { t } from 'i18next';

type Props = {
    files: File[];
    setFiles: React.Dispatch<React.SetStateAction<File[]>>;
    formats?: string[];
    maxSize?: number;
    error?: string;
    setError?: (error: string) => void;
};

type Validation = {
    file: File;
    formats?: string[];
    maxSize?: number;
};

export const FileUploadWithDragAndDrop: React.FC<Props> = ({ files, setFiles, formats, error, setError, maxSize }) => {
    const [dragging, setDragging] = useState(false);
    const [stringFormats, setStringFormats] = useState<string>();
    const [errorValid, setErrorValid] = useState<{ format?: string; size?: string }>({});
    const isMobile = useIsMobile(900);

    useEffect(() => {
        setStringFormats(formats?.length ? formats.map((ext) => `.${ext}`).join(', ') : undefined);
    }, [formats]);

    const validation = ({ file, formats, maxSize }: Validation) => {
        if (!formats?.some((format) => file.name.toLowerCase().endsWith(`.${format}`))) {
            setErrorValid(({ format }) => ({ format: format ? `${format} ${file.name}` : file.name }));
        } else if (maxSize && file.size > maxSize) {
            setErrorValid(({ size }) => ({ size: size ? `${size} ${file.name}` : file.name }));
        } else {
            setFiles((files) => [...files, file]);
            setErrorValid({});
        }
    };

    const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(true);
        setErrorValid({});
    };

    const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
    };

    const handleDrop = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
        setError && setError('');

        const droppedFiles = Array.from(e.dataTransfer.files);

        droppedFiles.forEach((file) => {
            validation({ file, formats, maxSize });
        });

        setErrorValid(({ format, size }) => ({
            format: format ? `${format} ${t('formatsError')}` : '',
            size: size ? `${size} ${t('maxSizeError')}` : '',
        }));
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = Array.from(e.target.files || []);
        setError && setError('');

        selectedFiles.forEach((file) => {
            validation({ file, formats, maxSize });
        });

        setErrorValid(({ format, size }) => ({
            format: format ? `${format} ${t('formatsError')}` : '',
            size: size ? `${size} ${t('maxSizeError')}` : '',
        }));
    };

    const handleRemoveFile = (index: number) => {
        setFiles(files.filter((_, i) => index !== i));
        setError && setError('');
    };

    return (
        <div>
            <div
                className={classNames(styles.uploadArea, {
                    [styles.errorArea]: errorValid.format || errorValid.size || error,
                })}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                {dragging ? (
                    t('dropFiles')
                ) : (
                    <>
                        <label htmlFor="fileInput" className={styles.uploadButton}>
                            <img src={Clip} alt="clip" />
                            {isMobile ? `${t('upload')} ${t('files')}` : t('upload')}
                        </label>
                        {!isMobile && <span>{`${t('orDragAndDrop')} ${t('files')}`}</span>}
                    </>
                )}
            </div>
            <input
                type="file"
                multiple
                style={{ display: 'none' }}
                onChange={handleFileChange}
                id="fileInput"
                accept={stringFormats}
            />
            {errorValid && (
                <div className={styles.error}>
                    {errorValid.format && (
                        <div className={styles.error__format}>
                            {errorValid.format}
                            <br />
                            {`${t('formats')}: ${stringFormats}`}
                        </div>
                    )}
                    {errorValid.size && maxSize && (
                        <div className={styles.error__size}>
                            {errorValid.size}
                            <br />
                            {t('maxFileSize')} {`${maxSize / 1024 / 1024} MB`}
                        </div>
                    )}
                </div>
            )}

            {error && <div className={styles.error}>{error}</div>}

            <div className={styles.files}>
                {files.map((file, index) => (
                    <div key={index} className={styles.file}>
                        <span>{file.name}</span>
                        <img src={close} alt="delete" onClick={() => handleRemoveFile(index)} />
                    </div>
                ))}
            </div>
        </div>
    );
};

import { useAuth } from '@/app/AuthHook';
import React, { ReactNode, useCallback } from 'react';

import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { UserService } from '@/shared/api';

type Props = {
    setTooltipContent: (p: ReactNode) => void;
};

export const UserButtons: React.FC<Props> = ({ setTooltipContent }) => {
    const { user, setUser } = useAuth();

    const handleLogout = useCallback(() => {
        UserService.getLogout()
            .then(() => {
                setUser && setUser(null);
            })
            .catch(() => alert(t('logoutError')));
    }, [setUser]);

    const handleMouseEnter = useCallback(
        () =>
            setTooltipContent(
                <div className={styles.tooltipContent}>
                    <span onClick={handleLogout} className={styles.tooltipContent__link}>
                        {t('logout')}
                    </span>
                </div>
            ),
        [setTooltipContent, handleLogout]
    );

    return user?.ID ? (
        <div
            className={styles.avatar}
            data-tooltip-id="header-end-tooltip"
            data-tooltip-class-name={styles.tooltip}
            data-tooltip-place="bottom-end"
            onMouseEnter={handleMouseEnter}
        >
            {user.Avatar ? (
                <img className={styles.avatar__img} src={user.Avatar} alt="avatar" />
            ) : (
                <div className={styles.avatar__empty} />
            )}
        </div>
    ) : (
        <Link to="/sign-in" className={styles.signIn}>
            <Trans i18nKey="menuSignIn" />
        </Link>
    );
};

import React, { ReactNode, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { Language } from '../language/Language';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import logo from './assets/logo.svg';
import hamburger from './assets/hamburger.svg';
import close from './assets/close.svg';
import classNames from 'classnames';
import { Donate } from '@/features/changeDonate/ui/Donate';
import { useIsMobile } from '@/shared/utils';
import { UserButtons } from './ui/UserButtons/UserButtons';
import { Tooltip } from 'react-tooltip';
import { useAuth } from '@/app/AuthHook';
import { Feedback } from '@/features/changeFeedback/ui/Feedback';

export const Header: React.FC = () => {
    const [active, setActive] = useState(false);
    const isMobile = useIsMobile(900);
    const { user } = useAuth();
    const [tooltipContent, setTooltipContent] = useState<ReactNode | null>(null);

    const isAuthorized = useMemo(() => !!user?.ID, [user]);

    const handleToggle = () => {
        setActive((prev) => {
            document.body.style.overflow = prev ? '' : 'hidden';
            return !prev;
        });
    };
    return (
        <div className={styles.container}>
            <header className={styles.header}>
                <div style={{ display: 'flex' }}>
                    <span className={styles.burger} onClick={handleToggle}>
                        <img src={hamburger} alt="BurgerMenu" />
                    </span>
                    <Link className={styles.logo} to="/">
                        <span className={styles.LogoTitle}>{t('title').toUpperCase()}</span>
                        <img src={logo} alt="Go Companion" />
                    </Link>
                </div>

                <nav className={classNames(styles.navbar, { [styles['navbar_active']]: active })}>
                    <span className={styles.close} onClick={handleToggle}>
                        <img src={close} alt="close menu" />
                    </span>
                    <Link className={styles['navbar-item']} to="/players" onClick={active ? handleToggle : undefined}>
                        <Trans i18nKey="menuPlayers" />
                    </Link>
                    <Link
                        className={styles['navbar-item']}
                        to="/tournaments"
                        onClick={active ? handleToggle : undefined}
                    >
                        <Trans i18nKey="menuTournaments" />
                    </Link>

                    {isAuthorized ? (
                        <Link
                            to="/go-glass"
                            className={classNames(styles['navbar-item'], styles.experimental)}
                            onClick={active ? handleToggle : undefined}
                        >
                            GoGlass
                        </Link>
                    ) : (
                        <>
                            <span
                                className={classNames(styles.disabledItem, styles.experimental)}
                                data-tooltip-id="goglass"
                            >
                                GoGlass
                            </span>
                            <Tooltip
                                id="goglass"
                                place={isMobile ? 'bottom-start' : 'bottom'}
                                variant="light"
                                className={styles.tooltip}
                            >
                                <span>{t('You need to be authorized')}</span>
                            </Tooltip>
                        </>
                    )}
                    <Link className={styles['navbar-item']} to="/about" onClick={active ? handleToggle : undefined}>
                        <Trans i18nKey="menuAboutCompany" />
                    </Link>
                    {isMobile && <Donate onClick={active ? handleToggle : undefined} />}
                    {isMobile && <Feedback onClick={active ? handleToggle : undefined} />}
                </nav>
                <div className={styles.end}>
                    {!isMobile && <Donate />}
                    {!isMobile && <Feedback />}
                    <Language setTooltipContent={setTooltipContent} />
                    <UserButtons setTooltipContent={setTooltipContent} />
                    <Tooltip
                        id="header-end-tooltip"
                        className={classNames(styles.tooltip, styles.notPadding)}
                        clickable
                        opacity={1}
                        variant="light"
                        noArrow
                        openOnClick={isMobile}
                    >
                        {tooltipContent}
                    </Tooltip>
                </div>
            </header>
        </div>
    );
};

import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

type Props = {
    title: string;
    subtitle?: string;
    button?: ReactNode;
    img: string;
};

export const InfoPage: React.FC<Props> = ({ title, subtitle, button, img }) => (
    <div className={styles.info}>
        <img src={img} alt="info" />
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.subtitle}>{subtitle}</p>
        {button}
    </div>
);

import React, { useState } from 'react';
import Modal from 'react-modal';
import styles from './styles.module.scss';
import TinkoffWidget from './TinkoffDonationWidget/TinkoffDonationWidget';
import close from '@app/ui/header/assets/close.svg';
import { useTranslation } from 'react-i18next';

Modal.setAppElement('#root');

type Props = {
    className?: string;
    onClick?: () => void;
};

export const Donate: React.FC<Props> = ({ className, onClick }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation('donate');

    const openModal = () => {
        onClick && onClick();
        setIsOpen(true);
    };
    const closeModal = () => setIsOpen(false);
    return (
        <div className={className}>
            <button onClick={openModal} className={styles.Button}>
                {t('button')}
            </button>
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                className={styles.modalContent}
                overlayClassName={styles.modalOverlay}
                bodyOpenClassName={styles.modalOpen}
            >
                <button onClick={closeModal} className={styles.closeButton}>
                    <img src={close} alt={t('close')} />
                </button>
                <div className={styles.modalBody} id="tinfoffWidgenContainer">
                    <h1 className={styles.Title}>{t('title')}</h1>
                    <TinkoffWidget />
                </div>
            </Modal>
        </div>
    );
};
